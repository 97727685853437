export const setDataTablesState = (state, dataTablesState) => {
    state.dataTablesState = dataTablesState;
};

export const setSearchQuery = (state, query) => {
    state.searchQuery = query;
};

export const setFieldValueQuery = (state, { field, value }) => {
    if (field === null) {
        state.fieldValueQuery = null;

        return;
    }

    state.fieldValueQuery = {
        field: field,
        value: value,
    };
};

export const resetFieldValueQuery = (state) => {
    state.fieldValueQuery = null;
};

export const setActiveFilterAction = (state, { attribute, value }) => {
    // Remove the old value from the active filters
    state.activeFilters = state.activeFilters.filter(item => item.attribute !== attribute);

    // (Re)Add it to the active filters
    state.activeFilters.push({
        attribute: attribute,
        value: value,
    });
};

export const setFiltersAction = (state, filters) => {
    state.filters = filters;
};

export const toggleShowFilters = (state) => {
    state.showFilters = !state.showFilters;
};

export const setShowOnlyActive = (state, value) => {
    state.showOnlyActive = value;
};

export const setShowOnlyCurrent = (state, value) => {
    state.showOnlyCurrent = value;
};

export const setSelectedIds = (state, selectedIds) => {
    state.selectedIds = selectedIds;
};

export const setSelectedRowsData = (state, selectedRowsData) => {
    state.selectedRowsData = selectedRowsData;
};

export const setReadonlyIds = (state, readonlyIds) => {
    state.readonlyIds = readonlyIds;
};

export const addSelectedId = (state, selectedId) => {
    state.selectedIds.push(selectedId);
};

export const setLoading = (state, value) => {
    state.loading = value;
};
