<template>
    <div :id="'overview_' + uniqueIdentifier"
         class="overview"
         :class="{
             'overview--with-sticky-actions': actionsSticky,
             'overview--page-has-sticky-buttons': pageHasStickyButtonGroup,
             'overview--with-breadcrumbs-clone': showBreadcrumbsClone,
             'overview--scrollable': scrollable,
         }"
    >
        <component
            :is="filtersComponent"
            :id="'overview-filters_' + uniqueIdentifier"
            ref="filters"
            :loading="loading"
            :config="config"
            @reload="refresh()"
        />

        <slot name="header" :data="currentData" />

        <template v-if="shouldShowBreadcrumbsClone">
            <a v-if="!scrollable" ref="breadcrumbsCloneAnchor" href="#" class="overview__breadcrumbs-clone-anchor" />
            <div v-show="showBreadcrumbsClone" ref="breadcrumbsClone" class="overview__breadcrumbs-clone" />
        </template>

        <component
            :is="tableComponent"
            :id="'overview-table_' + uniqueIdentifier"
            ref="table"
            :actions-disabled="actionsDisabled"
            :config="config"
            :handle-action="handleAction"
            :scrollable="scrollable"
            @deleteItem="deleteItem"
            @draw="$emit('draw') && updateCurrentData()"
            @editItem="editItem"
            @editOrOpenItem="editOrOpenItem"
            @reorderItem="reorderItem"
            @selectItems="selectItems"
            @groupingAction="handleGroupingAction($event)"
            @update="$emit('update')"
            @xhr="$emit('xhr')"
            @xhrError="showError($event)"
            @before-refresh="$emit('before-refresh')"
        />

        <a v-if="!scrollable" ref="actionsAnchor" href="#" class="overview__actions-anchor" />

        <component
            :is="actionsComponent"
            :id="'overview-actions_' + uniqueIdentifier"
            ref="actions"
            :config="config"
            :disabled="actionsDisabledComputed"
            :handle-action="handleAction"
            :sticky="actionsSticky"
            @action="$emit('action', $event)"
        />
    </div>
</template>

<script>
    import { mapMutations, mapState } from '../store/helpers';
    import axios from 'axios';

    import DialogManager from '../services/DialogManager';
    import CreatesOverviewState from '../mixins/Overview/CreatesState';
    import DefaultOverviewMixin from '../mixins/Overview/Default';
    import HandlesActions from '../mixins/Overview/HandlesActions';
    import HandlesShortcuts from '../mixins/HandlesShortcuts';
    import OverviewFilters from './Overview/Filters.vue';
    import OverviewTable from './Overview/Table.vue';
    import OverviewActions from './Overview/Actions.vue';
    import UrlQueryHelper from '../services/UrlQueryHelper';

    export default {
        mixins: [CreatesOverviewState, DefaultOverviewMixin, HandlesActions, HandlesShortcuts],

        components: {
            'frm-overview-actions': OverviewActions,
            'frm-overview-filters': OverviewFilters,
            'frm-overview-table': OverviewTable,
        },

        props: {
            config: {
                type: Object,
                required: true,
            },

            actionsDisabled: {
                type: Boolean,
                default: false,
            },

            actionsComponent: {
                type: String,
                default: 'frm-overview-actions',
            },

            filtersComponent: {
                type: String,
                default: 'frm-overview-filters',
            },

            tableComponent: {
                type: String,
                default: 'frm-overview-table',
            },

            scrollable: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                reorderPromise: Promise.resolve(),
                currentData: [],
                actionsInViewport: true,
                pageHasStickyButtonGroup: false,
                showBreadcrumbsClone: false,
            };
        },

        computed: {
            ...mapState([
                'activeFilters', // N.B. Needed for HandlesActions
                'readonlyIds', // N.B. Needed for HandlesActions
                'selectedIds', // N.B. Needed for HandlesActions
                'selectedRowsData', // N.B. Needed for HandlesActions
                'uniqueIdentifier',
                'loading',
            ]),

            showOnlyActiveShouldSearchNegative() {
                return this.config.showOnlyActiveShouldSearchNegative;
            },

            initialSearchQuery() {
                return this.config.initialSearchQuery;
            },

            actionsDisabledComputed() {
                return this.actionsDisabled || this.actionIsLoading;
            },

            actionsSticky() {
                return !!this.config.buttons.length && !this.actionsInViewport;
            },

            shouldShowBreadcrumbsClone() {
                return !!document.querySelectorAll('.breadcrumbs').length && !(new UrlQueryHelper()).isModal();
            },
        },

        mounted() {
            this.$shortcut.$on('overview.create', () => {
                if (!this.actionsDisabled && this.createAction && this.canHandleShortcuts()) {
                    this.handleAction(this.createAction);
                }
            });

            if (!this.scrollable) {
                if (this.shouldShowBreadcrumbsClone) {
                    const breadcrumbs = document.querySelector('.breadcrumbs').cloneNode(true);
                    breadcrumbs.classList.add('breadcrumbs--smaller');

                    this.$refs.breadcrumbsClone.appendChild(breadcrumbs);

                    this.$options.breadcrumbsCloneAnchorObserver = new IntersectionObserver(entries => {
                        this.showBreadcrumbsClone = entries[0].intersectionRatio === 0;
                    }, {
                        rootMargin: '-80px',
                        threshold: 0,
                    });

                    this.$options.breadcrumbsCloneAnchorObserver.observe(this.$refs.breadcrumbsCloneAnchor);
                }

                this.pageHasStickyButtonGroup = !!document.querySelectorAll('.form-button-group--sticky').length;

                this.$options.observer = new IntersectionObserver(entries => {
                    this.actionsInViewport = entries[0].intersectionRatio > 0;
                }, {
                    rootMargin: this.pageHasStickyButtonGroup ? '-50px' : '0px',
                    threshold: 0,
                });

                this.$options.observer.observe(this.$refs.actionsAnchor);
            }
            // Save filters to the shared state
            this.setFiltersAction(this.config.filters);
        },

        beforeDestroy() {
            if (this.$options.observer) {
                this.$options.observer.disconnect();
                this.$options.observer = null;
            }

            if (this.$options.breadcrumbsCloneAnchorObserver) {
                this.$options.breadcrumbsCloneAnchorObserver.disconnect();
                this.$options.breadcrumbsCloneAnchorObserver = null;
            }
        },

        methods: {
            ...mapMutations([
                'setFiltersAction',
                'setActiveFilterAction',
                'setReadonlyIds',
                'setSelectedIds',
                'setSelectedRowsData',
            ]),

            updateCurrentData() {
                this.currentData = this.$refs.table.getRowData();
            },

            selectItems(event) {
                this.setSelectedIds(event.ids);
                this.setSelectedRowsData(event.rowsData);
                this.setReadonlyIds(event.readonlyIds);
            },

            editItem() {
                if (this.editAction) {
                    this.handleAction(this.editAction);
                }
            },

            editOrOpenItem() {
                const action = this.editAction || this.openAction;

                if (action) {
                    this.handleAction(action);
                }
            },

            deleteItem() {
                if (this.deleteAction) {
                    this.handleAction(this.deleteAction);
                }
            },

            reorderItem(event) {
                // execute reorder-actions in queue with a promise
                this.reorderPromise = this.reorderPromise.then((previousResult) => {
                    // check if the previous promise was successfull, otherwise skip request
                    if (previousResult === false) {
                        return Promise.resolve(false);
                    }

                    return axios.post(this.config.sortableAction, {
                        id: event.id,
                        position: event.position,
                        extraProperties: event.extraProperties,
                    }).then(() => {
                        // When we have a grouped config variable or there are extra properties, refresh  (currently used for reordering into a different group)
                        if (this.config.grouped || Object.keys(event.extraProperties).length > 0) {
                            this.$refs.table.refresh();
                        }
                        this.$emit('reordered-item');

                        return Promise.resolve(true);
                    }).catch(() => {
                        // when action failed, refresh the overview and show an error. Also cancel queued actions
                        this.$refs.table.refresh(() => {
                            DialogManager.alert(this.$t('base.errors.general'));
                            // reset the promise
                            this.reorderPromise = Promise.resolve();
                        });
                        // reject the current queue
                        return Promise.resolve(false);
                    });
                });
            },

            refresh(callback = null) {
                this.$refs.table.refresh(callback);
            },

            selectRow(id) {
                // DataTables reselects all selected rows after a refresh. However,
                // this callback is triggered before that, so we must use a setTimeout.
                setTimeout(() => {
                    this.$refs.table.selectRow(id);
                });
            },

            getRowCount() {
                return this.$refs.table.getRowCount();
            },

            setUrl(url) {
                this.config.url = url;
                this.$refs.table.setUrl(url);
            },

            setFilters(filters) {
                Object.keys(filters).forEach(attribute => {
                    this.setActiveFilterAction({ attribute, value: filters[attribute] });
                });
            },

            showError(errorMessage) {
                return DialogManager.alert(`<strong>${errorMessage || this.$t('base.errors.general')}</strong>`);
            },

            handleGroupingAction(groupingValue) {
                if (!this.config.groupingAction) {
                    return;
                }

                this.handleAction({
                    ...this.config.groupingAction,
                    url: window.decodeURIComponent(this.config.groupingAction.url).replace(':grouping-value', groupingValue),
                });
            },
        },
    };
</script>
