<script>
import S from 'string';
import OverviewTable from '../Overview/Table';
import iconRenderer from '../../services/iconRenderer';

export default {
    extends: OverviewTable,

    methods: {
        rowGroupStartRender(rows, group) {
            const totalAmount = rows.data().toArray().reduce((acc, row) => acc + row.amount, 0);
            const showDirectDebitIcon = !!rows.data()[0].show_direct_debit_icon;

            const patient = `<strong class="billables-group-patient">${S(group).escapeHTML().toString()}</strong>`;
            const extra = `<span> | ${this.$tc(rows.data()[0].grouping_amount_translation_key, rows.count(), { number: rows.count() })} | ${this.$c(totalAmount)}</span>`;
            const directDebitIcon = () => `<span class="billables-group-icon hint--top" aria-label="${this.$t('financial.messages.patient-direct-debit')}">
                ${iconRenderer('bank')}
            </span>`;

            const title = `
                <span class="billables-group">
                    ${patient}
                    ${showDirectDebitIcon ? directDebitIcon() : ''}
                    ${extra}
                </span>`;

            return OverviewTable.methods.rowGroupStartRender.call(this, rows, title);
        },
    },
};
</script>

<style lang="scss">
.billables-group {
    align-items: center;
    display: flex;
    gap: rhythm(.5);
}

.billables-group-icon {
    svg {
        display: inline-block;
        fill: $color__dark;
        height: rhythm(2);
        width: rhythm(2);
    }
}
</style>
